.earlybirdies_page {
  padding-bottom: 70px;
  background: #ffffff;
  &__main {
    //min-height: 100vh;
    display: flex;
    flex-direction: column;
    &-image {
      display: none;
    }
  }
  &__inner {
    margin: 15px 0 160px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: url("../../assets/images/bg_image_phone.png") no-repeat;
    background-size: 350px 476px;
    background-position: right 85px;
    padding-right: 400px;
  }
  &__logo {
    display: flex;
    align-items: center;
    width: 221px;
    height: 75px;
    & img {
      max-width: 100%;
      height: 100%;
    }
  }
  &__title {
    margin-top: 10px;
    max-width: 610px;
  }
  &__desc {
    max-width: 881px;
    margin-top: 10px;
  }
  &__investor_link {
    margin: 30px 0;
  }
  &__note {
    margin-top: 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    max-width: 555px;
    color: rgba(0, 0, 0, 0.44);
  }
  &__scramble {
    &-desc {
      margin-top: 10px;
      max-width: 1040px;
    }
    &-circles {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 60px;
    }
    &-circle {
      width: 350px;
      min-width: 350px;
      height: 350px;
      border-radius: 50%;
      border: 1px solid #FFD200;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      &:hover {
        transform: scale(1.01);
        background: #FFD200;
        transition: all 0.2s ease-out;
      }
      &-content {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 25px;
        text-align: center;
        white-space: pre-line;
        & h3 {
          font-size: 25px;
          line-height: 30px;
          font-weight: 600;
        }
        & p {
          font-size: 25px;
          line-height: 30px;
          font-weight: 400;
          margin-top: 20px;
        }
        & span {
          font-size: 15px;
          line-height: 30px;
          color: #9E98A3;
          margin-top: 5px;
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }
    }
  }

  &__works {
    margin-top: 150px;
    &-desc {
      margin-top: 10px;
    }
    &-video {
      display: block;
      margin-top: 70px;
      text-align: center;
      & img {
        cursor:url("../../assets/images/icon_play.svg"), auto;
      }
    }
  }

  &__contacts {
    margin-top: 160px;
  }

  &__faqs {
    margin-top: 160px;
    &-list {
      margin-top: 55px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 70px;
    & a:last-child {
      margin-left: 20px;
    }
  }

  .faq_question {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
    &__title {
      font-weight: 600;
    }
    &__answer {
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 992px) {
  .earlybirdies_page {
    &__main {
      &-image {
        margin: 30px auto 0;
        display: inline-block;
        max-width: 80%;
        & svg {
          max-width: 100%;
        }
      }
    }
    &__content {
      background: unset;
      padding-right: 0;
    }
    &__help {
      &-title {
        margin-top: 100px;
      }
      &-title2 {
        margin-top: 100px;
        margin-bottom: 57px;
      }
      &-list {
        margin-top: 70px;
      }
    }
    &__investor_link {
      margin: 20px auto 10px;
    }
  }
}

@media screen and (max-width: 700px) {
  .earlybirdies_page {
    &__investor_link {
      width: 270px;
      height: 45px;
      font-size: 18px;
      line-height: 18px;
    }
    &__main {
      padding-top: 0;
    }

    //// HELP section
    &__help {
      &-list {
        margin-top: 50px;
      }

      .faq_question {
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .earlybirdies_page {
    &__buttons {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & a:last-child {
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .earlybirdies_page {
    &__title {
      margin-top: 10px;
    }
  }
}