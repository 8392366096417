@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

html {
  font-size: clamp(14px, 1.1111111111vw, 16px);
  @media screen and (min-width: 1440px) {
    font-size: 1.1111111111vw;
  }
}

* {
  font-family: 'Poppins', -apple-system, 'Roboto', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

main {
  min-height: 40vmax;
  background: #000000;
}

.container {
  //max-width: 81.25rem; // 1300px
  min-width: 20rem; // 320px
  padding: 0 80px; // 0 10px
  width: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    padding: 0 20px;
  }
}

.hidden {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
}

.tl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

header {
  z-index: 1;
}

footer {
  background: #000;
  padding-bottom: 50px;
}


.f-45-55 {
  font-size: 45px;
  line-height: 55px;
}

.f-42-48 {
  font-size: 42px;
  line-height: 48px;
}

.f-25-30 {
  font-size: 25px;
  line-height: 30px;
}

.f-24-36 {
  font-size: 24px;
  line-height: 36px;
}

.f-26-32 {
  font-size: 26px;
  line-height: 32px;
}

.f-18 {
  font-size: 18px;
}

.f-16 {
  font-size: 16px;
}

.f-400 {font-weight: 400;}
.f-500 {font-weight: 500;}
.f-600 {font-weight: 600;}

@media screen and (max-width: 1024px) {
  .f-45-55 {
    font-size: 40px;
    line-height: 50px;
  }

  .f-42-48 {
    font-size: 37px;
    line-height: 43px;
  }

  .f-25-30 {
    font-size: 20px;
    line-height: 30px;
  }

  .f-26-32 {
    font-size: 21px;
    line-height: 31px;
  }

  .f-18 {
    font-size: 16px;
  }

  .f-16 {
    font-size: 14px;
  }
}

@media screen and (max-width: 414px) {
  .f-45-55 {
    font-size: 26px;
    line-height: 36px;
  }

  .f-42-48 {
    font-size: 29px;
    line-height: 39px;
  }

  .f-25-30 {
    font-size: 18px;
    line-height: 20px;
  }
}

.scr-button {
  min-width: 300px;
  max-width: 300px;
  min-height: 64px;
  border: 2px solid #000000;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  &:hover {
    opacity: 0.85;
    transform: scale(1.01);
    transition: all 0.3s ease-out;
  }
  @media screen and (max-width: 1024px) {
    min-width: 250px;
    max-width: 250px;
    min-height: 50px;
    font-size: 18px;
    line-height: 24px;
  }
}

.scr-button.white {
  background: #FFF;
  border: 1px solid #FFD200;
}

.scr-button.yellow {
  background: #FFD200;
  border: 1px solid #FFD200;
}