footer {
    margin-bottom: 50px;

    hr {
        border-top: 2px solid #FFFFFF;
        opacity: 0.1;
        margin-bottom: 40px;
        margin-top: 0;
        width: 99.5%;
    }

    .disabled {
        display: none;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;

        .footer_col1 {
            width: 40%;

            .row1 {
                display: flex;
                align-items: center;
                width: 100%;

                .logo {
                    width: 80px;
                    height: auto;
                }

                .nav {
                    margin-left: 75px;

                    ul {
                        display: flex;
                        padding: 0;

                        li {
                            list-style: none;
                            padding: 0 10px;
                            opacity: .5;

                            &:hover {
                                opacity: 1;

                                a {
                                    color: #FFD11C;
                                }
                            }
                        }

                        .active {
                            opacity: 1;
                        }
                    }
                }
            }

            .row2 {
                margin-top: 60px;

                .copywrite {

                    p {
                        display: block;
                        margin-block-start: 1em;
                        margin-block-end: 1em;
                        margin-inline-start: 0;
                        margin-inline-end: 0;
                    }

                    .text {
                        opacity: .5;
                        width: 100%;

                        @media screen and (max-width: 1600px) {
                            width: 95%;
                        }

                        @media screen and (max-width: 1536px) {
                            width: 90%;
                        }

                        @media screen and (max-width: 1440px) {
                            width: 95%;
                        }

                        @media screen and (max-width: 1366px) {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .footer_col2 {
            width: 40%;

            .nav_top {
                ul {
                    display: flex;
                    padding: 0;

                    li {
                        list-style: none;
                        padding: 0 10px;

                        &:hover {
                            a {
                                color: #FFD11C;
                            }
                        }
                    }
                }
            }

            .nav_bottom {
                margin-top: 70px;

                ul {
                    display: flex;
                    padding: 0;

                    li {
                        list-style: none;
                        padding: 0 10px;

                        p {
                            margin: 0;
                        }

                        a {
                            text-decoration: none;

                            &:hover {
                                color: #FFD11C;
                            }
                        }

                        ul {
                            display: flex;
                            flex-direction: column;
                            padding: 0;

                            li {
                                list-style: none;
                                padding: 0;
                                margin-top: 20px;

                                a {
                                    opacity: .5;

                                    &:hover {
                                        opacity: 1;
                                        color: #FFD11C;
                                    }
                                }
                            }
                        }
                    }

                    .legal {
                        margin: 0.1em 0 0 1.5em;
                    }
                }
            }
        }

        .footer_col3 {
            width: 15%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            .row1 {
                a {
                    text-decoration: none;
                }
            }

            .row2 {
                display: flex;
                margin-top: 60px;

                .facebook {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    border-radius: 100%;
                    border: 1px solid #FFD11C;
                    margin-right: 13px;

                    .footer_facebook {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    svg {
                        margin-bottom: -3px;

                        path {
                            fill: white;
                        }
                    }

                    &:hover {
                        background: #FFD11C;

                        svg {
                            path {
                                fill: black;
                            }
                        }
                    }
                }

                .linkedIn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    border-radius: 100%;
                    border: 1px solid #FFD11C;
                    margin-right: 13px;

                    .footer_linkedIn {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    svg {

                        path {
                            fill: white;
                        }
                    }

                    &:hover {
                        background: #FFD11C;

                        svg {
                            path {
                                fill: black;
                            }
                        }
                    }
                }

                .insta {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    border-radius: 100%;
                    border: 1px solid #FFD11C;

                    .footer_insta {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    svg {

                        path {
                            fill: white;
                        }
                    }

                    &:hover {
                        background: #FFD11C;

                        svg {
                            path {
                                fill: black;
                            }
                        }
                    }
                }
            }
        }
    }
}