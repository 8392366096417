$disabledGrey: #F4F4F5;
$primaryYellow: #FFD200;
$disabledGrey: #F4F4F5;
$circleSize: 11px;

.earn_stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 0;
  &.vertical {
    height: 250px;
    width: 60px;
    &::after {
      width: 2px;
      height: 100%;
      top: 0;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
    flex-direction: column;
  }

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    background: $primaryYellow;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: -1;
  }
  &__wrap {
    padding: 135px 52px 20px 20px;
  }
  &__step {
    background: $disabledGrey;
    width: $circleSize;
    height: $circleSize;
    min-width: $circleSize;
    min-height: $circleSize;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primaryYellow;
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: $primaryYellow;
      transform: scale(1);
      transition: all 0.3s ease-out;
    }
    &.active {
      &::after {
        transition: all 0.3s ease-out;
        transform: scale(1.2);
      }
      .earn_stepper__amount {
        background: $primaryYellow;
      }
      .earn_stepper__friends {
        font-weight: 600;
      }
    }
    &:nth-child(1) {
      .earn_stepper__amount {
        width: 50px;
        height: 50px;
      }
    }
    &:nth-child(2) {
      .earn_stepper__amount {
        width: 65px;
        height: 65px;
      }
    }
    &:nth-child(3) {
      .earn_stepper__amount {
        width: 80px;
        height: 80px;
      }
    }
    &:nth-child(4) {
      .earn_stepper__amount {
        width: 96px;
        height: 96px;
      }
    }
    &:nth-child(5) {
      .earn_stepper__amount {
        width: 115px;
        height: 115px;
      }
    }
  }
  &__amount, &__friends {
    position: absolute;
    cursor: pointer;
  }
  &__amount {
    bottom: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    border: 1px solid $primaryYellow;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  &__friends {
    top: calc(100% + 5px);
    left: 0;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
  }
}

@media screen and (max-width: 610px) {
  .earn_stepper {
    &__wrap {
      padding: 80px 45px 15px 10px;
    }
    &__step {
      &:nth-child(1) {
        .earn_stepper__amount {
          width: 30px;
          height: 30px;
        }
      }
      &:nth-child(2) {
        .earn_stepper__amount {
          width: 35px;
          height: 35px;
        }
      }
      &:nth-child(3) {
        .earn_stepper__amount {
          width: 40px;
          height: 40px;
        }
      }
      &:nth-child(4) {
        .earn_stepper__amount {
          width: 45px;
          height: 45px;
        }
      }
      &:nth-child(5) {
        .earn_stepper__amount {
          width: 50px;
          height: 50px;
        }
      }
    }
    &__amount, &__friends {
      font-size: 10px;
      line-height: 12px;
    }
  }
}