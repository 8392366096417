$disabledGrey: #F4F4F5;
$primaryYellow: #FFD200;
$disabledGrey: #F4F4F5;
$circleSize: 60px;

.simple_stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 0;
  &.vertical {
    height: 250px;
    width: 60px;
    &::after {
      width: 2px;
      height: 100%;
      top: 0;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
    flex-direction: column;
  }

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    background: $primaryYellow;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: -1;
  }

  &__step {
    background: $disabledGrey;
    width: $circleSize;
    height: $circleSize;
    min-width: $circleSize;
    min-height: $circleSize;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primaryYellow;
  }
}