.aff_influencers_page {
  padding-bottom: 50px;
  &__inner {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 45px;
    padding-bottom: 160px;
    background: #FFF;
  }
  &__desc {
    margin-top: 20px;
  }
  &__link {
    width: 100%;
    margin: 20px auto 0;
    max-width: 430px !important;
    min-height: 50px !important;
    font-size: 18px !important;

    &.two {
      margin-top: 40px;
    }
  }

  & section {
    margin-top: 50px;
  }

  &__how {
    & p {
      margin-top: 20px;
    }
    &_works {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
      gap: 20px;
    }

    &_circles {
      display: flex;
      align-items: center;
    }
    $size: 265px;

    &_circle {
      width: $size;
      min-width: $size;
      height: $size;
      border-radius: 50%;
      border: 1px solid #FFD200;
      background: #FFFFFF;
      align-items: center;
      justify-content: center;
      margin: 10px;
      &:hover {
        transform: scale(1.01);
        background: #FFD200;
        transition: all 0.2s ease-out;
        & li:before {
          background: #000;
        }
      }
      &_content {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 25px;
        white-space: pre-line;
        & a {
          text-decoration: underline;
          margin-top: 20px;
          color: #9E98A3;
        }
        & p {
          margin-top: 20px;
        }

        & ul {
          margin-top: 20px;
        }
        & ul li {
          padding-left: 15px;
          position: relative;
          & b {
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
          }
          &::before {
            content: '';
            width: 8px;
            height: 8px;
            background: #ffd200;
            position: absolute;
            border-radius: 50%;
            top: 10px;
            left: 0;
          }
        }
      }
    }
  }

  &__earn {
    &_desc {
      margin-top: 20px;
    }
    &_pic {
      margin-top: 20px;
      text-align: center;
      & img {
        max-width: 100%;
      }
    }
  }

  &__start_earn {
    margin-top: 60px;
    &_title {
      text-align: center;
    }
    &_stepper {
      width: 75%;
      margin: 30px auto 0;
    }
    &_options {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 50px;
      margin-top: 20px;
      & span {
        display: block;
        text-align: center;
      }
    }
  }
}

.earn_block {
  &__container {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-column-gap: 7px;
    align-items: center;
    margin: 0 auto;
    max-width: 870px;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 40px;
    margin-top: 115px;
    text-align: right;
  }
}

@media screen and (max-width: 1050px) {
  .aff_influencers_page {
    &__start_earn {
      &_stepper {
        width: 80%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .aff_influencers_page {
    &__how {
      &_circles {
        flex-wrap: wrap;
        justify-content: center;
      }
      &_works {
        flex-direction: column;
      }
    }
    &__start_earn {
      margin-top: 70px;
    }
  }
}

@media screen and (max-width: 760px) {
  .earn_block {
    &__container {
      grid-template-columns: minmax(0, 1fr);
      grid-column-gap: unset;
    }
    &__subtitle {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .aff_influencers_page {
    &__start_earn {
      &_inner {
        display: grid;
        grid-template-columns: minmax(60px, 60px) minmax(0, 1fr);
        margin-top: 20px;
      }
      &_stepper {
        width: 100%;
        margin-top: 0;
      }
      &_options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        grid-template-columns: unset;
        grid-column-gap: unset;
        margin-top: 0;
        gap: 30px;
        padding: 20px;
      }
    }
  }
}
