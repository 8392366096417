.page_404 {
  padding: 7.5rem 0; //107px
  &__wrapper {
    color: #000000;
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
  }
  &__content {
    padding: 4rem 2rem;
    background: #FFF;
    border-radius: 10px;
    width: 100%;
    max-width: 50rem;
    font-size: 1rem;
    line-height: 1.7rem;
    & a {
      text-decoration: underline;
    }
  }
}