#header {
    width: 100%;
    overflow: auto;
    nav {
        display: flex;
        height: 80px;

        .logo {
            margin-right: auto;
            cursor: pointer;
            align-self: center;
            opacity: 0;
            margin-left: 3px;

            &.visibleLogo {
                opacity: 1;
            }

            @media screen and (max-width: 1024px) {
                margin: 0;
            }
        }

        .navListCenter {
            display: none;
            margin: 0 auto;
            padding-left: 220px;

            li:hover {
                a {
                    color: #FFD11C;
                }
            }

            @media screen and (max-width: 1680px) {
                padding-left: 185px;
            }

            @media screen and (max-width: 1024px) {
                padding-left: 0;
                margin: 0;
            }
        }

        .navListRight {
            margin-left: auto;
            display: flex;
            align-items: center;

            .signInBtn {
                background: transparent;
                border: none;
                margin-right: 20px;
                cursor: pointer;

                &:hover {
                    p {
                        color: #FFD11C;
                    }
                }
            }

            .headerGetStarted {

                @media screen and (min-width: 1920px) {
                    padding: 0 10px;
                }
            }

            .dots-button {
                padding: 20px 10px 15px 15px;
                margin-left: 0;

                .dots {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    img {
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                &:hover .dots img {
                    position: relative;

                    &:nth-child(1) {
                        animation: moveDots .3s ease;
                    }

                    &:nth-child(2) {
                        animation: moveDots .4s ease;
                    }

                    &:nth-child(3) {
                        animation: moveDots .5s ease;
                    }

                }

                @keyframes moveDots {
                    from {
                        bottom: 0;
                    }

                    to {
                        bottom: 8px;
                    }
                }
            }

            @media screen and (max-width: 1024px) {
                margin: 0;
            }
        }

        ul {
            display: flex;
            align-items: center;

            li {
                margin-right: 20px;
                list-style: none;
                opacity: 0.5;

                &.active {
                    opacity: 1;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        @media screen and (max-width: 1024px) {
            justify-content: space-between;
        }
    }
}

.openMenuPopup {
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #FFD11C !important;
    animation: menuAppearance 0.5s ease;

    .logo {
        opacity: 1;
    }

    .navListCenter {
        li:hover {
            a {
                color: #ffffff;
            }
        }
    }

    .navListRight {
        .signInBtn:hover {
            p {
                color: #ffffff;
            }
        }
    }

    @keyframes menuAppearance {
        0% {
            height: 0;
            background-color: #000000;
        }

        100% {
            height: 100%;
            background-color: #FFD11C;
        }
    }

    .openMenu {
        display: flex;
        justify-content: space-between;
        height: auto;

        .openMenuList {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 0;
            width: max-content;
            animation: menuItemsAppearance 0.3s ease;

            @keyframes menuItemsAppearance {
                0% {
                    opacity: 0;
                }
                25% {
                    opacity: 0;
                }
                50% {
                    opacity: 0;
                }
                75% {
                    opacity: 0.5;
                }
                100% {
                    opacity: 1;
                }
            }

            &:hover li a {
                opacity: 0.2;
            }

            li {
                display: flex;
                align-items: center;
                list-style: none;
                width: 100%;
                cursor: pointer;
                position: relative;

                img {
                    display: none;
                    width: 15px;
                    height: 15px;
                    transition: ease .1s;

                    @media screen and (max-width: 768px) {
                        width: 10px;
                        height: 10px;
                    }
                }

                a {
                    color: #000000;
                    line-height: 90px;
                    transition: ease .1s;
                }

                &:hover {

                    img {
                        display: flex;
                    }

                    a {
                        margin-left: 35px;
                        opacity: 1;

                        @media screen and (max-width: 768px) {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        .openMenuRightPart {
            display: flex;
            margin-top: auto;
            margin-bottom: 30px;
            align-items: center;
            animation: menuItemsAppearance 1s ease;

            a {
                color: #000000;
            }

            .email {
                margin-right: 40px;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        position: static;
    }
}

@media screen and (min-width: 1700px) {
    .get-started {
        font-size: 20px!important;
        white-space: nowrap;
    }
}