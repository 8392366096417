.avatar {
  &__wrap {
    position: relative;
    display: inline-block;
  }
  &__active {
    border: 2px solid #ffd200;
    border-radius: 50%;
  }
  width: 100%;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 50%;
  overflow: hidden;
  &__image, &__initials {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__badge {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: #ffd200;
    border: 2px solid #FFFFFF;
    border-radius: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &_260 {
      font-size: 26px;
      line-height: 38px;
      bottom: -18px;
      width: 80px;
    }
    &_140 {
      font-size: 15px;
      line-height: 20px;
      bottom: -13px;
      width: 44px;
    }
    &_65 {
      font-size: 10px;
      line-height: 13px;
      bottom: -10px;
      min-width: 30px;
    }
    &_70 {
      font-size: 10px;
      line-height: 13px;
      bottom: -10px;
      width: 30px;
    }
  }
  &__initials {
    background: #ebeaec;
    display: flex;
    & span {
      color: #9E98A3;
      margin: auto;
    }
  }
}