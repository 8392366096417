.input-text-field {
  padding-top: 10px;
  &__input-group {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  &__input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 21px 5px 0;
    border-bottom: 1px solid #D8D6DA;
    &:focus {
      border-bottom: 2px solid #000;
    }
  }

  &__label {
    font-size: 16px;
    line-height: 24px;
    color: #000;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;

    pointer-events: none;
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
  }

  &__clear {
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    &-hidden { display: none; }
    &:hover path {
      fill: #cdcacf;
      -webkit-transition: fill 0.5s ease;
      -o-transition: fill 0.5s ease;
      transition: fill 0.5s ease;
    }
  }

  &__input:focus ~ &__label,
  &__input-filled ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #000;
    top: -4px;
    left: 0;
  }

  &__input:-webkit-autofill ~ &__label {
    font-size: 12px;
    line-height: 16px;
    color: #9E98A3;
    top: -4px;
    left: 0;
  }

  &_error {
    .input-text-field {
      &__input {
        border-color: #F93C10;
      }
    }
  }

  &__error {
    color: #F93C10;
    font-size: 14px;
    margin-top: 5px;
    display: none;
    &_visible {display: block;}
  }
}