.contact-form {
  display: flex;
  &__desc {
    max-width: 710px;
  }
  &__content {

  }
  &__left {
    margin-right: 100px;
    & .input-text-field {
      margin-top: 30px;
    }
  }
  &__form {
    max-width: 570px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    & .input-text-field {
      width: 100%;
    }
  }

  &__submit {
    width: 350px;
    min-width: 350px;
    height: 350px;
    border: 2px solid #FFD200;
    border-radius: 50%;
    background: #FFD200;
    color: #000000;
    &:disabled,  &:disabled:hover {
      background: #43B129;
      border: 2px solid #43B129;
      color: #FFF;
    }
    &:hover {
      background: #FFF;
      color: #000;
    }
  }
}

@media screen and (max-width: 992px) {
  .contact-form {
    flex-direction: column;
    align-items: center;
    &__row {
      gap: 30px;
    }
    &__left {
      margin-right: 0;
      max-width: unset;
    }
    &__right {
      padding-top: 50px;
    }
    &__desc, &__form {
      width: 100%;
      max-width: unset;
    }
  }
}

@media screen and (max-width: 700px) {
  .contact-form {
    &__submit {
      width: 50vmin;
      height: 50vmin;
      min-width: unset;
    }
  }
}

@media screen and (max-width: 414px) {
  .contact-form {
    &__row {
      flex-direction: column;
      gap: unset;
    }
  }
}