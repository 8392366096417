.round_page {
  background: #000000;
  padding-bottom: 50px;
  &__title {
    text-align: center;
  }
  &__inner {
    max-width: 1024px;
    margin: 0 auto;
    padding-top: 45px;
    padding-bottom: 160px;
    background: #FFF;
  }
  &__desc {
    margin-top: 20px;
  }
  &__countdown {
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
  }
  &__investor_link {
    margin: 20px auto 0;
    max-width: 430px!important;
    min-height: 50px!important;
    font-size: 18px!important;
  }

  &__investor_link.two {
    margin-top: 40px;
  }

  &__investor_link.three {
    margin-top: 50px;
  }


  &__founders {
    margin-top: 50px;
    &_list {
      margin: 20px -20px 0;
      overflow: auto;
      &_inner {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: 20px;
        min-width: 800px;
        padding: 0 20px 15px;
      }
    }
    &_item {
      background: #FFFFFF;
      border: 2px solid #9E98A320;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 20px;
      height: 100%;
      overflow: hidden;
      &.signup {
        display: flex;
      }
      &_centered {
        margin: auto;
        text-align: center;
        & a {
          margin-top: 20px;
          display: inline-block;
          border: 2px solid #FFD200;
          border-radius: 100px;
          padding: 5px 20px;
        }
      }
      & ul {
        margin-top: 20px;
      }
      & ul li {
        position: relative;
        padding-left: 15px;
        &::after {
          content: '';
          width: 6px;
          height: 6px;
          background: #FFD200;
          position: absolute;
          border-radius: 50%;
          top: 9px;
          left: 0;
        }
      }
      &_site {
        display: block;
        margin-top: 10px;
      }
      &_avatars {
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
      &_avatar {
        &:not(:first-child) {
          margin-left: -10px;
        }
      }
    }
  }

  &__how {
    margin-top: 100px;
    &_stepper {
      width: 75%;
      margin: 50px auto 0;
    }
    &_options {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 50px;
      margin-top: 20px;
      & span {
        display: block;
        text-align: center;
      }
    }
    &_option {
      text-align: center;
      & div:first-child {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  &__why {
    margin-top: 100px;
    &_circles {
      margin: 40px -20px 0;
      overflow: auto;
      &_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 20px;
      }
    }

    $size: 265px;

    &_circle {
      width: $size;
      min-width: $size;
      height: $size;
      border-radius: 50%;
      border: 1px solid #FFD200;
      background: #FFD200;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      &:hover {
        transform: scale(1.01);
        background: #FFFFFF;
        transition: all 0.2s ease-out;
      }
      &_content {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 25px;
        text-align: center;
        white-space: pre-line;
        & a {
          text-decoration: underline;
          margin-top: 20px;
          color: #9E98A3;
        }
        & p {
          margin-top: 20px;
        }
      }
    }
  }
  &__learn {
    display: inline-block;
    margin-top: 10px;
    text-decoration: underline;
  }
}

@media screen and (max-width: 940px) {
  .round_page {
    &__how {
      .simple_stepper.vertical {
        height: 270px;
      }
      &_inner {
        display: grid;
        grid-template-columns: minmax(60px, 60px) 1fr;
        margin-top: 30px;
      }
      &_stepper {
        width: 100%;
        margin: 0;
      }
      &_options {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        grid-template-columns: unset;
        grid-column-gap: unset;
        margin-top: 0;
        padding-left: 20px;
        & span {
          display: block;
          text-align: center;
        }
      }
      &_option {
        text-align: left;
        margin-top: 20px;
        & div:first-child {
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .round_page {
    padding-top: 0;
  }
}

@media screen and (max-width: 455px) {
  .round_page {
    &__how {
      .simple_stepper.vertical {
        height: 305px;
      }
      &_option:nth-child(2) {
        margin-top: 35px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .round_page {
    &__founders {
      margin-top: 50px;
    }
    &__why {
      margin-top: 50px;
    }
    &__faq {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 395px) {
  .round_page {
    &__how {
      .simple_stepper.vertical {
        height: 88%;
      }
    }
  }
}