.invest-progress-bar {
  margin-top: 47px;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-block {
      & div:first-child {
        color: #000000;
      }
    }
  }
  &__bar {
    height: 60px;
    width: 100%;
    background: #ebeaec;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    & .full {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #000000;
    }
    &.zero {
      & div {background: #ebeaec;}
    }
    & div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0;
      background: #FFD200;
      min-width: 50px;
    }
    & .percent {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
  }
}

@media screen and (max-width: 991px) {
  .invest-progress-bar {
    margin-top: 47px;

    &__top {
      &-block:last-child {
        text-align: right;
      }
      .f-42 {
        font-size: 26px!important;
        line-height: 32px!important;
      }
    }
  }
}