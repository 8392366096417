.aff_friends_page {
  padding-bottom: 50px;
  &__inner {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 45px;
    padding-bottom: 160px;
    background: #FFF;
  }
  &__desc {
    margin-top: 20px;
  }
  &__link {
    width: 100%;
    margin: 20px auto 0;
    max-width: 630px !important;
    min-height: 50px !important;
    font-size: 18px !important;
    &.two {
      margin-top: 50px;
    }
    &.three {
      margin-top: 70px;
    }
  }

  & section {
    margin-top: 50px;
  }

  &__how {
    &_stepper {
      width: 75%;
      margin: 20px auto 0;
    }
    &_options {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column-gap: 50px;
      margin-top: 20px;
      & span {
        display: block;
        text-align: center;
      }
    }
    &_option {
      text-align: center;
      & div:first-child {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  &__why {
    margin-top: 100px;
    &_circles {
      margin: 40px -20px 0;
      overflow: auto;
      &_inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 20px;
      }
    }

    $size: 265px;

    &_circle {
      width: $size;
      min-width: $size;
      height: $size;
      border-radius: 50%;
      border: 1px solid #FFD200;
      background: #FFD200;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
      &:hover {
        transform: scale(1.01);
        background: #FFFFFF;
        transition: all 0.2s ease-out;
      }
      &_content {
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 25px;
        text-align: center;
        white-space: pre-line;
        & a {
          text-decoration: underline;
          margin-top: 20px;
          color: #9E98A3;
        }
        & p {
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .aff_friends_page {
    &__how {
      .simple_stepper.vertical {
        height: 270px;
      }
      &_inner {
        display: grid;
        grid-template-columns: minmax(60px, 60px) 1fr;
        margin-top: 30px;
      }
      &_stepper {
        width: 100%;
        margin: 0;
      }
      &_options {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        grid-template-columns: unset;
        grid-column-gap: unset;
        margin-top: 0;
        padding-left: 20px;
        & span {
          display: block;
          text-align: center;
        }
      }
      &_option {
        text-align: left;
        margin-top: 20px;
        & div:first-child {
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 615px) {
  .aff_friends_page {
    &__how {
      .simple_stepper.vertical {
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .aff_friends_page {
    &__how {
      .simple_stepper.vertical {
        height: 300px;
      }
      &_option:nth-child(2) {
        margin-top: 25px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .aff_friends_page {
    &__how {
      .simple_stepper.vertical {
        height: 83%;
      }
    }
  }
}